<template>
  <div class="personal">
    <div v-if="$screenWidth>1200">
      <div class="personal_top">
        <div class="personal_top_content">
          <div class="personal_top_content_left">
            <span>关注</span>
            <span>0</span>
          </div>
          <img src="../../assets/imgs/btx1.png" alt="">
          <div class="personal_top_content_right">
            <span>点赞</span>
            <span>0</span>
          </div>
        </div>
      </div>

      <div class="personal_tab">
        <span v-for="(item,index) in tab" :key="index"
          :class="[activeTab == item.name ? 'personal_tab_span_active' : '']"
          @click="activeTab = item.name"><router-link :to="item.path">{{item.title}}</router-link></span>
      </div>

      <div class="personal_tab_pane">
        <div class="personal_tab_pane_content">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div v-else class="mobile">
      <div class="personal_top">
        <div class="personal_top_content">
          <div class="personal_top_content_left">
            <span>关注</span>
            <span>0</span>
          </div>
          <img src="../../assets/imgs/btx1.png" alt="">
          <div class="personal_top_content_right">
            <span>点赞</span>
            <span>0</span>
          </div>
        </div>
      </div>
      <div class="personal_tab">
        <span v-for="(item,index) in tab" :key="index"
          :class="[activeTab == item.name ? 'personal_tab_span_active' : '']"
          @click="activeTab = item.name"><router-link :to="item.path">{{item.title}}</router-link></span>
      </div>

      <div class="personal_tab_pane">
        <div class="personal_tab_pane_content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  data(){
    return{
      activeTab: '个人信息',
      tab:[
        {
          title: '个人信息',
          path: '/personal/info',
          name: 'Info'
        },
        {
          title: '我的活动',
          path: '/personal/course',
          name: 'Course'
        },
        {
          title: '我的会员',
          path: '/personal/vip',
          name: 'Vip'
        }
      ],
    }
  },
  watch:{
    $route(to,from){
      this.activeTab = to.name
    }
  },
}
</script>

<style scoped lang="scss">
.personal{
  .personal_top{
    position: relative;
    height: 300px;
    background-color: #E85D04;
    color: #fff;
    margin-bottom: 100px;
    .personal_top_content{
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 30%;
      transform: translate(-50%);
      font-size: 0;
      height: 120px;
      &::after{
        height:100%;
        content:'';
        display:inline-block;
        vertical-align:middle;
      }
      .personal_top_content_left{
        display:inline-block;
        vertical-align:middle;
      }
      span{
        display: block;
        font-size: 24px;
      }
      img{
        width: 120px;
        height: 120px;
        border-radius: 60px;
        border: 5px solid #fff;
        box-sizing: border-box;
        transform: translateY(50%);
        display:inline-block;
        vertical-align:middle;
        position: relative;
        bottom: 0;
        margin: 0 80px;
      }
      .personal_top_content_right{
        display:inline-block;
        vertical-align:middle;
      }

    }
  }
  .personal_tab{
    color: #222;
    font-size: 2em;
    width: 800px;
    margin: 0 auto;
    span{
      cursor: pointer;
      &:hover{
        border-bottom: 3px solid #000;
      }
      &:first-child{
        float: left;
      }
      &:last-child{
        float: right;
      }
      a{
        text-decoration: none;
        color: #222;
      }
    }
  }

  .personal_tab_pane{
    background-color: #ddd;
    padding: 30px 0;
    .personal_tab_pane_content{
      width: 1200px;
      margin: 0 auto;
      padding: 0 120px;
      padding-bottom: 20px;
      background-color: #fff;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      border: 1px solid #ebeef5;
      box-sizing: border-box;

    }
  }

  .mobile{
    .personal_top{
      position: relative;
      height: 20vh;
      background-color: #E85D04;
      color: #fff;
      margin-bottom: 5vh;
      .personal_top_content{
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 70vw;
        transform: translate(-50%);
        font-size: 0;
        height: 10vh;
        &::after{
          height:100%;
          content:'';
          display:inline-block;
          vertical-align:middle;
        }
        .personal_top_content_left{
          display:inline-block;
          vertical-align:middle;
        }
        span{
          display: block;
          font-size: 12px;
        }
        img{
          width: 60px;
          height: 60px;
          border-radius: 30px;
          border: 5px solid #fff;
          box-sizing: border-box;
          transform: translateY(50%);
          display:inline-block;
          vertical-align:middle;
          position: relative;
          bottom: 0;
          margin: 0 30px;
        }
        .personal_top_content_right{
          display:inline-block;
          vertical-align:middle;
        }

      }
    }
    .personal_tab{
      color: #222;
      font-size: 1em;
      width: 100vw;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      span{
        cursor: pointer;
        &:hover{
          border-bottom: 3px solid #000;
        }
        &:first-child{
          float: left;
        }
        &:last-child{
          float: right;
        }
        a{
          text-decoration: none;
          color: #222;
        }
      }
    }

    .personal_tab_pane{
      background-color: #ddd;
      padding: 3vw 4vw;
      .personal_tab_pane_content{
        width: 100%;
        margin: 0 auto;
        padding: 3vw 4vw;
        padding-bottom: 20px;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        border: 1px solid #ebeef5;
        box-sizing: border-box;

      }
    }
  }
}

.personal_tab_span_active{
  border-bottom: 3px solid #000;
}
</style>
