<template>
  <div id="personal">
  	<div class="personal-top">
  		<!-- <img src="https://abc.xnrun.com/image/default/C58F7136FDBB4243867274AD7E4E64CB-6-2.png" > -->
  		<img class="personal-avatar" :src="personalData.personalImage" >
  		<!-- <div class="personal-identity">
  			<img class="personal-identity-bg" src="https://abc.xnrun.com/image/default/70C25E768CB14151B43407D015A35547-6-2.png" >
  			<span class="personal-identity-text">官方认证</span>
  			<span class="personal-identity-text">{{personalData.sort?'小聂学员':'助理教练'}}</span>
  		</div> -->
  	<!-- . -->
  	</div>
  	<div class="personal-content">
  		<!-- <el-tabs v-model="activeName" @tab-click="handleClick">
  			<el-tab-pane label="简介" name="first"></el-tab-pane>
  			<el-tab-pane label="照片墙" name="second"></el-tab-pane>
  			<el-tab-pane label="视频墙" name="third"></el-tab-pane>
  			<el-tab-pane label="战队" name="fourth"></el-tab-pane>
  		</el-tabs> -->
  		<div class="personal-tabs" style="border-bottom: 1px solid #d4d4d4;">
  			<a href="#personal-intro2" @click="onTabs(0)" :style="{borderBottom:curIndex === 0 ? '5px solid #999' : ''}">简介</a>
  			<a href="#personal-pics2" @click="onTabs(1)" :style="{borderBottom:curIndex === 1 ? '5px solid #999' : ''}">照片墙</a>
  			<a href="#personal-videos2" @click="onTabs(2)" :style="{borderBottom:curIndex === 2 ? '5px solid #999' : ''}">视频墙</a>
  			<a href="#personal-team2" @click="onTabs(3)" :style="{borderBottom:curIndex === 3 ? '5px solid #999' : ''}">战队</a>
  		</div>
  		<div id="personal-intro" class="personal-item" v-html="personalData.intro">
  			<a name="personal-intro2" class="personal-href"></a>
  		</div>
  		<div id="personal-pics" class="personal-item">
  			<a name="personal-pics2" class="personal-href"></a>
  			<p class="personal-title" style="">照片墙</p>
  			<el-image
  			    style="width: 150px; height: 150px"
  				v-for="i in personalData.honorImage"
            :key="i.uuid"
  			    :src="i"
  			    :preview-src-list="personalData.honorImage"
            fit='cover'>
  			</el-image>
  		</div>
  		<div id="personal-videos" class="personal-item">
  			<a name="personal-videos2" class="personal-href"></a>
  			<p class="personal-title">视频墙</p>
  			<!-- <video  height="" v-for="(i,index) in personalData.videos" controls="" :key="index">
  				<source :src="i" type="video/mp4"></source>
  			</video> -->
        <video width="800" :src="i" v-for="(i,index) in personalData.video" controls="" :key="index"></video>
  		</div>
  		<div id="personal-team" class="personal-item">
  			<a name="personal-team2" class="personal-href"></a>
  			<p class="personal-title">战队</p>
  			<div class="personal-team-bottom" v-if="team">
  				<img :src="team.showImage">
  				<div class="personal-team-right">
  					<span>战队名称：{{team.name}}</span>
  					<span>战队成员数量：{{team.memberNum}}</span>
  				</div>
  			</div>
  		</div>
  	</div>

  </div>
</template>

<script>
  export default{
    data(){
      return{
        activeName: 'first',
        curIndex: 0,
        personalData:{},
        team:{}
      }
    },
    created() {
      console.log(this.$route)
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    	this.getData()
    	this.getTeam()
    },
    methods:{
    	handleClick(){

    	},
    	onTabs(index){
    		this.curIndex = index
    	},
    	async getData(){
    		let res = await this.$http.get('official/team/Member/getTeamMemberById',{params:{
    			uuid: this.$route.query.id
    		}})
    		console.log(res)
    		this.personalData = res.data.data
    		if(this.personalData.honorImage){
    			this.personalData.honorImage = this.personalData.honorImage.split(',')
    			if(this.personalData.honorImage[this.personalData.honorImage.length - 1] === ''){
    				this.personalData.honorImage.pop()
    			}
    		}
    		if(this.personalData.video){
    			this.personalData.video = this.personalData.video.split(';')
    			if(this.personalData.video[this.personalData.video.length - 1] === ''){
    				this.personalData.video.pop()
    			}
    		}
        console.log({personalData:this.personalData})
    	},
    	async getTeam(){
    		let res = await this.$http.get('official/team/getTeamById',{params:{
    			teamId:this.$route.query.teamId
    		}})
    		this.team = res.data.data
    		console.log({team:res})
    	}

    }
  }
</script>

<style scoped="">
  #personal{

  }
  .personal-top{
  	/* background-image: url(https://abc.xnrun.com/image/default/C58F7136FDBB4243867274AD7E4E64CB-6-2.png); */
    background-color: #ff7708;
  	background-size: cover;
  	background-position: center;
  	width: 100%;
  	/* height: 30vw; */
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	padding: 20px;
  	color: #FFFFFF;
  }
  .personal-avatar{
  	width: 20%;
    /* width: 150px;

  	height: 150px; */
  	/* border-radius: 75px; */
  }
  .personal-identity{
  	transform: translateY(-20px);
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  }
  .personal-identity-bg{
  	position: absolute;
  }
  .personal-identity-text{
  	z-index: 2021;
  	font-size: 15px;
  }
  .personal-name{
  	font-size: 30px;
  }
  .personal-content{
  	width: 50%;
  	margin: 80px auto;
  	padding: 0 50px;
    text-align: left;
  }
  .personal-tabs a{
  	display: inline-block;
  	color: #999;

  	font-size: 25px;
  	margin-right: 45px;
  	height: 50px;
  	text-decoration: none;
    border-bottom: 1px solid #d4d4d4;
  }
  .personal-item{
  	margin: 50px 0;
  	position: relative;
  }
  .personal-href{
  	position: absolute;
  	top: -100px;
  }
  .personal-title{
  	color: #999;
  	font-size: 25px;
    border-bottom: 1px solid #d4d4d4;
  }
  #personal-pics .el-image{
  	margin-right: 5px;
  }
  #personal-pics{

  }
  #personal-videos video{
  	width: 150px;
  	height: 150px;
  	margin-right: 10px;
  }
  .personal-team-bottom{
  	display: flex;
  	padding: 20px 30px;
  	padding-left: 0;
  }
  .personal-team-bottom img{
  	width: 200px;
  }
  .personal-team-right{
  	margin-left: 30px;
  	display: flex;
  	flex-direction: column;
  	font-size: 30px;
  }
  img{
    object-fit: cover;
    object-position: top;
  }
  video{
  	object-fit: cover;
  }
  ::v-deep .el-image__inner {
      object-position: top;
  }
  @media screen and (max-width: 1200px) {
  	.personal-content{
  		width: 100%;
  	}
  }
</style>
